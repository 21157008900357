
@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title{
  font-family: "Parisienne", cursive;
  
}

.title, .cake{
  font-size:10rem;
  margin:0;
}

.gallery{
  display:flex;
  justify-content: center;
  flex-wrap:wrap;
  width: 100vw;
}

.gallery__img{
  width:50vw;
  max-width:500px;
  border:.5rem solid whitesmoke;
  border-radius: 1rem;
  margin:1rem;
  box-shadow:.2rem .2rem 1rem hsla(0, 0%, 0%, 20%);
}